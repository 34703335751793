import React from 'react';
import { Link } from 'gatsby';

// import style
import '../style/index.scss';
// import SEO tool
import SEO from '../components/seo';
import Header from '../components/header';
import Footer from '../components/footer';

import { toAnt } from '../components/functions';

export default () => {
    return (<>
    <SEO title="about" />
    <div className="app">
        <Header />
        <div className="content">
            <h1>ABOUT</h1>
            <p>This is just a fun little side project I developed in response to the "Ant Colony" Facebook group. Hope you find it helpful!</p>
            <p>That said, this tool is provided as is. So use it wisely, and don't use it to do anything that would make the Queen upset!</p>
        </div>
        <Footer />        
    </div>
    </>);
};